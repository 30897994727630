import { ProcessingStage, ProcessingStageData } from "../Facility/Processing";
import {
  Baseline,
} from "./Baseline";
import {
  CattleEmission,
  CattleFeed,
  CattleHousingAndManure,
  CattleManureManagementSystemType,
  ExternalSource,
  InternalSource,
} from "./CattleBaseline";

export interface Stage {
  id: string;
  farmId: string;
  name: string;
  type: string;
  productionSystem: string;
}

export const availableMmsTypeForMonthlyStoragePeriods = [
  CattleManureManagementSystemType.LiquidCover,
  CattleManureManagementSystemType.LiquidCrust,
  CattleManureManagementSystemType.LiquidNoCrust,
  CattleManureManagementSystemType.Pit,
];

export const availableMmsTypeForOverOrUnderStoragePeriod = [
  CattleManureManagementSystemType.DeepBeddingActiveMixing,
  CattleManureManagementSystemType.DeepBeddingNoMixing,
];

export interface DairyV2Input {
  cattleProductivity: "HIGH" | "LOW";
  animalsPresentAtStart: number;
  animalsPresentAtEnd: number;
  averageWeightAtStart: number;
  averageWeightNewAnimals: number;
  internalSources: InternalSource[];
  externalSources: ExternalSource[];
}

export interface DairyV2InputDairyCow extends DairyV2Input {
  startDate?: Date | string;
}

export interface DairyV2InputContinuousGrowing extends DairyV2Input {
  averageWeightAtEnd?: number;
  averageAge?: number;
}

export interface DairyV2Output {
  cowsToAnotherStage: number;
  averageWeightOfCowsLeavingTheStage: number;
  cowsSold: number;
  pricePerCowSold?: number;
  numberOfMortalitiesCows: number;
  averageWeightAtMortalityCows: number;
}

export interface DairyV2OutputCalves {
  weanedCalvesToAnotherStage: number;
  weanedCalvesSold: number;
  averageWeightAtBirth?: number;
}

export type DairyV2MilkProduction = {
  averageMilkFat: number
  averageMilkProtein: number
  yearlyMilkProduction: number
}

export type DairyV2OutputDairyCow = DairyV2Output & DairyV2OutputCalves & DairyV2MilkProduction;

export interface DairyV2OutputContinuousGrowing extends DairyV2Output {
  averageAge: number;
}

export interface DairyV2CommonStageData {
  feed: CattleFeed;
  housing: CattleHousingAndManure;
  emissions?: CattleEmission;
}
export interface DairyV2DairyCowStageData extends DairyV2CommonStageData {
  input: DairyV2InputDairyCow;
  output: DairyV2OutputDairyCow;
}

export interface DairyV2ContinuousGrowingStageData extends DairyV2CommonStageData {
  input: DairyV2InputContinuousGrowing;
  output: DairyV2OutputContinuousGrowing;
}
export interface DairyV2Stage extends Stage {
  stageData:
    | DairyV2ContinuousGrowingStageData
    | DairyV2DairyCowStageData
    | ProcessingStageData;
}

export interface DairyV2Baseline extends Baseline {
  stages: Array<DairyV2Stage | ProcessingStage>;
}

import { Maybe } from "../../../../graphql/types";
import {
  AnimalSourceOrigin,
  BaselineFeed,
  BaselineFeedWithOrigin,
  BaselinePropertyValue,
} from "./Baseline";

export enum TargetAnimal {
  COWS = "cows",
  CALVES = "calves",
}

export enum CattleBeddingType {
  Sand = "SAND",
  SawDust = "SAW_DUST",
  WheatStraw = "WHEAT_STRAW",
  WoodShavings = "WOOD_SHAVINGS",
}

export interface CattleBeddingSystem {
  beddingType: CattleBeddingType;
  beddingAmount: BaselinePropertyValue;
}

export interface MothersMilk {
  isMothersMilk?: boolean
  amount: BaselinePropertyValue;
  crudeProteinContent: BaselinePropertyValue;
  digestibility: BaselinePropertyValue;
  dryMatterContent: BaselinePropertyValue;
}

export interface FreshGrass extends MothersMilk {
  type: string;
  grossEnergyContent?: BaselinePropertyValue;
  urinaryEnergy?: BaselinePropertyValue;
  ashContent?: BaselinePropertyValue;
  // TODO - Change to neutralDetergentFiber and fix in and out mapper
  naturalDetergentFiber?: BaselinePropertyValue;
}

export interface CattleFeed {
  databaseFoundation: string;
  dietCharacterisation:
    | "FEEDLOT_CORN"
    | "FEEDLOT_OTHER_GRAIN"
    | "HIGH_FORAGE_HIGH_QUALITY"
    | "HIGH_FORAGE_LOW_QUALITY"
    | "MIXED_RATIONS";
  averageMilkFat?: Maybe<number>;
  mothersMilk?: MothersMilk;
  compoundFeeds?: Maybe<BaselineFeed[]>;
  singleIngredients?: BaselineFeedWithOrigin[];
  freshGrass: Array<FreshGrass>;
}

export enum CattleManureManagementSystemType {
  // NO STORAGE
  AerobicForced = "AEROBIC_FORCED",
  AerobicNatural = "AEROBIC_NATURAL",
  AnaerobicHighLeakHighGastight = "ANAEROBIC_HIGH_LEAK_HIGH_GASTIGHT",
  AnaerobicHighLeakLowGastight = "ANAEROBIC_HIGH_LEAK_LOW_GASTIGHT",
  AnaerobicHighLeakOpen = "ANAEROBIC_HIGH_LEAK_OPEN",
  AnaerobicLowLeakHighGastight = "ANAEROBIC_LOW_LEAK_HIGH_GASTIGHT",
  AnaerobicLowLeakLowGastight = "ANAEROBIC_LOW_LEAK_LOW_GASTIGHT",
  AnaerobicLowLeakOpen = "ANAEROBIC_LOW_LEAK_OPEN",
  CompostingIntensiveWindrow = "COMPOSTING_INTENSIVE_WINDROW",
  CompostingInVessel = "COMPOSTING_IN_VESSEL",
  CompostingPassiveWindrow = "COMPOSTING_PASSIVE_WINDROW",
  CompostingStaticPile = "COMPOSTING_STATIC_PILE",
  DailySpread = "DAILY_SPREAD",

  // OVER / UNDER 1 MONTH
  DeepBeddingActiveMixing = "DEEP_BEDDING_ACTIVE_MIXING",
  DeepBeddingNoMixing = "DEEP_BEDDING_NO_MIXING",

  // 1 to 12
  LiquidCover = "LIQUID_COVER",
  LiquidCrust = "LIQUID_CRUST",
  LiquidNoCrust = "LIQUID_NO_CRUST",
  Pit = "PIT",

  // NO STORAGE
  SolidAdditives = "SOLID_ADDITIVES",
  SolidBulking = "SOLID_BULKING",
  SolidCovered = "SOLID_COVERED",
  SolidStorage = "SOLID_STORAGE",
  UncoveredAnaerobicLagoon = "UNCOVERED_ANAEROBIC_LAGOON",
}

export enum StoragePeriod {
  ONE_MONTH = "1 month",
  THREE_MONTHS = "3 month",
  FOUR_MONTHS = "4 month",
  SIX_MONTHS = "6 month",
  TWELVE_MONTHS = "12 month",
  OVER_ONE_MONTH = "over 1 month",
  UNDER_ONE_MONTH = "under 1 month",
}

export enum ManurePhase {
  LiquidSlurry = "LIQUID_SLURRY",
  Solid = "SOLID",
}

export interface CattleManureManagementSystem {
  id: string;
  mmsType: CattleManureManagementSystemType;
  storagePeriod?: StoragePeriod;
  share: BaselinePropertyValue;
  manurePhase?: ManurePhase;
}

export interface CattleHousingAndManure {
  timeInFeedlot: Maybe<BaselinePropertyValue>;
  timeInHousing: Maybe<BaselinePropertyValue>;
  timeGrazingLargeAreas: Maybe<BaselinePropertyValue>;
  timeGrazingPastures: Maybe<BaselinePropertyValue>;
  timeInHousingTied: Maybe<BaselinePropertyValue>;
  manureSystems: CattleManureManagementSystem[];
  beddingSystems?: CattleBeddingSystem[];
}

export interface CattleHousing {
  beddingSystems?: CattleBeddingSystem[];
  calves: Omit<CattleHousingAndManure, "beddingSystems">;
  cows: Omit<CattleHousingAndManure, "beddingSystems">;
}

export interface CattleEmission {
  methaneEntericFermentation?: string;
  NMVOC?: string;
  methaneMms?: string;
  nitrousOxideLeaching?: string;
  nitrousOxideDirect?: string;
  nitrousOxideVolatilization?: string;
  nitrogenOxidesFromManureStorage?: string;
  amoniaStorage?: string;
  amoniaHousing?: string;
  amoniaYard?: string;
  dinitrogenStorage?: string;
  PM2_5?: string;
  PM10?: string;
  TSP?: string;
  NOP?: string;
}

export interface ExternalSource {
  id: string;
  distanceTruck?: number;
  numberAnimals: number;
  origin: AnimalSourceOrigin;

  // shrinkingRate and weightLoss are the same thing.
  // shrinkingRate is used for Beef and will eventually become weight loss for FE baselines like DairyV2
  shrinkingRate?: number;
  weightLoss?: number;
}

export interface InternalSource extends ExternalSource {
  farmId: string;
  farmName: string;
  originStageId: string;
  stageName: string;
  baselineRef?: string;
  animalType?: string;
  stageType: string;
}

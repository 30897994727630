export type Maybe<T> = T | null;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: Date;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type CattleAnimalProductivityDB = "HIGH" | "LOW";

export type CattleAnimalType = "CALF" | "COW";

export enum StageType {
  Breeding = "BREEDING",
  Fattening = "FATTENING",
  Growing = "GROWING",
  Hatching = "HATCHING",
  Laying = "LAYING",
  Processing = "PROCESSING",
  ContinuousGrowing = 'CONTINUOUS_GROWING',
  DairyCow = 'DAIRY_COW',
}

export interface StageDBModel {
  name: string;
  id: string;
  stageData: any;
}

export enum ReportingPeriod {
  Round = "ROUND",
  Year = "YEAR",
}

export enum WaterSourceType {
  GroundWater = "GROUND_WATER",
  SurfaceWater = "SURFACE_WATER",
}

export type ResourceUse = {
  __typename?: "ResourceUse";
  coalUse?: Maybe<Scalars["Float"]>;
  dieselUse?: Maybe<Scalars["Float"]>;
  electricityPhotovoltaicUse?: Maybe<Scalars["Float"]>;
  electricityUse?: Maybe<Scalars["Float"]>;
  electricityWindUse?: Maybe<Scalars["Float"]>;
  gasUse?: Maybe<Scalars["Float"]>;
  gasolineUse?: Maybe<Scalars["Float"]>;
  propaneOrLpgUse?: Maybe<Scalars["Float"]>;
  waterSource?: Maybe<WaterSourceType>;
  waterUse?: Maybe<Scalars["Float"]>;
};

export interface BaselineDBModel {
  createdAt: string;
  updatedAt?: Maybe<string>;
  farmId: string;
  name: string;
  oldName: string;
  averageAnnualTemperature?: Maybe<number>;
  description: string;
  lastUpdatedBy: string;
  productionProcessId: string;
  reference: string;
  reportingPeriod?: Maybe<ReportingPeriod>;
  resourceUse?: Maybe<ResourceUse>;
  roundsPerYear?: Maybe<number>;
  validFrom: string;
  validTo: string;
  year: number;
  calculationInProgress?: Maybe<boolean>;
  stages: StageDBModel[];
}

export interface CattleMilkProductionDBModel {
  amount?: Maybe<number>;
  fatContent: Maybe<number>;
  proteinContent?: Maybe<number>;
}

export interface CattleStockChangeDBModel {
  averageWeightCowsStart: number;
  cowsPresentAtEnd: number;
  cowsPresentAtStart: number;
  averageWeightCowsEnd?: number;
}

export interface CattleBeddingDBModel {
  amount: number;
  type: string;
}

export interface EmissionMitigationDBModel {
  change: number;
  emissionType: string;
}

export interface EnergyCarrierDBModel {
  amount: number;
  type: string;
}

export interface MothersMilkDBModel {
  amount: number | null;
  crudeProteinContent: number;
  digestibility: number; // digestibleEnergyContent
  dryMatterContent: number;
}

export interface FreshGrassDBModel extends MothersMilkDBModel {
  type: string;
  grossEnergyContent?: number;
  urinaryEnergy?: number;
  ashContent?: number;
  neutralDetergentFiber?: number;
}

export interface IngredientUseDBModel {
  id?: string;
  name: string;
  origin: string;
}

export interface FeedDBModel {
  quantity: number;
}

export interface CompundFeedInfoDBModel extends FeedDBModel {
  compoundFeedId: string;
  feedmillLocation?: string;
  ingredientUses: Array<IngredientUseDBModel>;
  name: string;
  outboundTransport?: {
    distance: number;
    mode: string;
  };
  water?: {
    amount: number;
    type: string;
  };
}

export type SingleIngredientUseDBModel = {
  ingredient: IngredientUseDBModel;
  ashContent?: number;
  crudeProteinContent?: number;
  digestibleEnergyContent?: number;
  distanceTransport?: number;
  dryMatterContent?: number;
  grossEnergyContent?: number;
  milk?: boolean;
  milkFromMother?: boolean;
  naturalDetergentFiberContent?: number;
  phosphorusContent?: number;
  quantity?: number;
  silage?: boolean;
  urinaryEnergyContent?: number;
};

export enum CompoundFeedDatabase {
  Afp_5 = "AFP_5",
  Afp_6_3 = "AFP_6_3",
  Gfli = "GFLI",
}

export interface CattleFeedingProgramDBModel {
  databaseFoundation: CompoundFeedDatabase;
  dietCharacterisation:
    | "FEEDLOT_CORN"
    | "FEEDLOT_OTHER_GRAIN"
    | "HIGH_FORAGE_HIGH_QUALITY"
    | "HIGH_FORAGE_LOW_QUALITY"
    | "MIXED_RATIONS";
  compoundFeeds?: Array<CompundFeedInfoDBModel>;
  singleIngredients?: Array<SingleIngredientUseDBModel>;
  freshGrass: Array<FreshGrassDBModel>;
}

export interface FeedingProgramCalfDBModel extends CattleFeedingProgramDBModel {
  averageMilkFat?: Maybe<number>;
  mothersMilk?: Maybe<MothersMilkDBModel>;
}

export interface CattleManureManagementSystemDBModel {
  id: string;
  share: number;
  mmsType: string;
  mmsViewModelType: string;
  storagePeriod?: string;
  manurePhase?: string;
}

export interface TimeSpentDBModel {
  timeInFeedlot: number;
  timeInHousing: number;
  timeInHousingTied?: Maybe<number>;
  timeOnLargeGrazingAreas: number;
  timeOnSmallGrazingAreas: number;
}

type AnimalSourceOrigin = "BACKGROUND_DATA" | "PRODUCTION_SYSTEM";

export interface CattleAnimalInputDBModel {
  id: string;
  origin: AnimalSourceOrigin;
  originStageId?: Maybe<string>;
  baselineRef?: Maybe<string>; // Needed internally (When it's internal source)
  originStageName?: Maybe<string>; // Needed internally (When it's internal source)
  originFarmId?: Maybe<string>; // Needed internally (When it's internal source)
  originFarmName?: Maybe<string>; // Needed internally (When it's internal source)
  animalType?: CattleAnimalType;
  stageType?: Maybe<string>;
  numberOfAnimals: number;
  transportDistance?: Maybe<number>;
  shrinkingRate?: number; // weightLoss value from dairy is assigned to shrinking rate.
}
